import {Vue} from 'vue-property-decorator';

export class StatusColeta {

    public static NOVO = new StatusColeta('NOVO', 1, 'Nova', 'info');
    public static MANIFESTADO = new StatusColeta('MANIFESTADO', 2, 'Manifestada', 'warning');
    public static CANCELADO = new StatusColeta('CANCELADO', 3, 'Cancelada', 'danger');
    public static NAO_REALIZADO = new StatusColeta('NAO_REALIZADO', 4, 'Não Realizada', 'danger');
    public static FINALIZADO = new StatusColeta('FINALIZADO', 5, 'Finalizada', 'success');
    public static EM_VIAGEM = new StatusColeta('EM_VIAGEM', 6, 'Em Viagem', 'warning');
    public static EXPIRADA = new StatusColeta('EXPIRADA', 7, 'Expirada', '');

    private constructor(
        private _key: string,
        private _value: number,
        private _description: string,
        private _type: string,
    ) {}

    public toString() {
        return this._key;
    }

    get value(): number {
        return this._value;
    }

    get description(): string {
        return this._description;
    }

    get type(): string {
        return this._type;
    }
}

export class StatusColetaLookup {

    public static lookup = [
        StatusColeta.NOVO,
        StatusColeta.MANIFESTADO ,
        StatusColeta.CANCELADO,
        StatusColeta.NAO_REALIZADO,
        StatusColeta.FINALIZADO,
        StatusColeta.EM_VIAGEM,
        StatusColeta.EXPIRADA,
    ].sort((a, b) => a.value - b.value); // mantém o array ordenado pelo valor

    public static default = StatusColeta.NOVO;

    private constructor() {}
}
