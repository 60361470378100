





















































































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {Company} from '@/models/Company';
import {Utils} from '@/utils/Utils';
import {UfLookup} from '@/enums/Uf';
import {DateUtils} from '@/utils/DateUtils';

const AuthStore = namespace('AuthStore');

@Component({
    name: 'DadosGerais',
})
export default class DadosGerais extends Vue {

    @AuthStore.State
    public currentCompany!: Company;

    @Prop()
    public dados: any;

    public filterBrDate = Vue.filter('brDate');

    public renderCliente(cliente): string {
        if (!cliente || !cliente.id) {
            return 'Não Informado';
        }
        return Utils.formatCnpjCpf(cliente.cnpjCpf) + ' - ' + cliente.razaoSocial;
    }

    public renderEmpresa(cliente): string {
        if (!cliente || !cliente.id) {
            return 'Não Informado';
        }
        return Utils.formatCnpjCpf(cliente.cnpj) + ' - ' + cliente.name;
    }

    public renderTipoColeta( data ): string {
        switch (data) {
            case '0':
                return 'No Cliente';
            case '1':
                return 'No Depósito';
            default:
                return 'Não Definido';
        }
    }

    public renderLocal(local) {
        return [
            local.logradouro,
            local.numero,
            local.bairro,
        ].filter(Boolean).join(', ');
    }

    public renderLocalCid(local) {
        return [
            local.cep,
            this.renderCidade(local.cidade),
        ].filter(Boolean).join(', ');
    }

    public renderCidade(cidade): string {
        if (!cidade || !cidade.id) {
            return 'Cidade não informada';
        }
        return cidade.nome + ' - ' + UfLookup.getUfByCod(cidade.codUf).toString();
    }

    public renderFone(fone): string {
        if (!fone) {
            return fone;
        }
        if (fone.length === 10) {
            return '(' + fone.substr(0, 2) + ') ' + fone.substr(2, 4) + '-' + fone.substr(6, 10);
        } else {
            return '(' + fone.substr(0, 2) + ') ' + fone.substr(2, 5) + '-' + fone.substr(7, 11);
        }
    }

    public formatDateFn(param) {
        if (!param.value) {
            return '-';
        }
        const date: Date = new Date(param.value);
        date.setTime(date.getTime() + (3 * 60 * 60 * 1000));
        return this.filterBrDate(date);
    }

    public getDiaFromData(param) {
        if (!param.value) {
            return '-';
        }
        const date: Date = new Date(param.value);
        date.setTime(date.getTime() + (3 * 60 * 60 * 1000));
        return [
            'Domingo',
            'Segunda-Feira',
            'Terça-Feira',
            'Quarta-Feira',
            'Quinta-Feira',
            'Sexta-Feira',
            'Sábado'][date.getDay()];
    }

    public renderHoraIniFim(full): string {
        if (full.horaFixa) {
            return 'Às ' + DateUtils.removeSegundos(full.horaFixa);
        } else if (full.horaInicio && full.horaFinal) {
            return 'De ' + DateUtils.removeSegundos(full.horaInicio) +
                ' até ' + DateUtils.removeSegundos(full.horaFinal);
        } else if (full.horaInicio && !full.horaFinal) {
            return 'A partir das ' + DateUtils.removeSegundos(full.horaInicio);
        } else if (!full.horaInicio && full.horaFinal) {
            return 'Até ' + DateUtils.removeSegundos(full.horaFinal);
        }
        return 'Não Informado';
    }
}
