export class Uf {
    public static RO = new Uf('RO', 11, 'Rondônia');
    public static AC = new Uf('AC', 12, 'Acre');
    public static AM = new Uf('AM', 13, 'Amazonas');
    public static RR = new Uf('RR', 14, 'Roraima');
    public static PA = new Uf('PA', 15, 'Pará');
    public static AP = new Uf('AP', 16, 'Amapá');
    public static TO = new Uf('TO', 17, 'Tocantins');

    public static MA = new Uf('MA', 21, 'Maranhão');
    public static PI = new Uf('PI', 22, 'Piauí');
    public static CE = new Uf('CE', 23, 'Ceará');
    public static RN = new Uf('RN', 24, 'Rio Grande do Norte');
    public static PB = new Uf('PB', 25, 'Paraíba');
    public static PE = new Uf('PE', 26, 'Pernambuco');
    public static AL = new Uf('AL', 27, 'Alagoas');
    public static SE = new Uf('SE', 28, 'Sergipe');
    public static BA = new Uf('BA', 29, 'Bahia');

    public static MG = new Uf('MG', 31, 'Minas Gerais');
    public static ES = new Uf('ES', 32, 'Espírito Santo');
    public static RJ = new Uf('RJ', 33, 'Rio de Janeiro');
    public static SP = new Uf('SP', 35, 'São Paulo');

    public static PR = new Uf('PR', 41, 'Paraná');
    public static SC = new Uf('SC', 42, 'Santa Catarina');
    public static RS = new Uf('RS', 43, 'Rio Grande do Sul');

    public static MS = new Uf('MS', 50, 'Mato Grosso do Sul');
    public static MT = new Uf('MT', 51, 'Mato Grosso');
    public static GO = new Uf('GO', 52, 'Goiás');
    public static DF = new Uf('DF', 53, 'Distrito Federal');

    private constructor(
        private _key: string,
        private _value: number,
        private _description: string,
    ) {}

    public toString() {
        return this._key;
    }

    get value(): number {
        return this._value;
    }

    get description(): string {
        return this._description;
    }
}

export class UfLookup {
    public static lookup = [
        Uf.RO,
        Uf.AC,
        Uf.AM,
        Uf.RR,
        Uf.PA,
        Uf.AP,
        Uf.TO,
        Uf.MA,
        Uf.PI,
        Uf.CE,
        Uf.RN,
        Uf.PB,
        Uf.PE,
        Uf.AL,
        Uf.SE,
        Uf.BA,
        Uf.MG,
        Uf.ES,
        Uf.RJ,
        Uf.SP,
        Uf.PR,
        Uf.SC,
        Uf.RS,
        Uf.MS,
        Uf.MT,
        Uf.GO,
        Uf.DF,
    ].sort((a, b) => a.value - b.value);

    public static default = Uf.RS;

    public static getUfByCod(cod: number) {
        return this.lookup.find((v) => v.value === cod) || this.default;
    }

    private constructor() {}
}
