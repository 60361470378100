












import {namespace} from 'vuex-class';
import {Component, Vue} from 'vue-property-decorator';
import {TipoColeta, TipoColetaLookup} from '@/enums/TipoColeta';

const AuthStore = namespace('AuthStore');

@Component({
    name: 'SelectTipoColeta',
})

export default class SelectTipoColeta extends Vue {

    public value: string = '';

    public get options(): TipoColeta[] {
        return TipoColetaLookup.lookup;
    }
}
