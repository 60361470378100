





































import {Component, Prop, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {Company} from '@/models/Company';
import {Utils} from '@/utils/Utils';

const AuthStore = namespace('AuthStore');

@Component({
    name: 'Documentos',
})

export default class Documentos extends Vue {

    @AuthStore.State
    public currentCompany!: Company;

    @Prop()
    public documentoList: any;

    public renderNumeroSerie(full): string {
        return full.numeroNF + '-' + full.serieNF;
    }

    public renderEmissor(emissor): string {
        if (!emissor || !emissor.id) {
            return 'Não Informado';
        }
        return Utils.formatCnpjCpf(emissor.cnpjCpf) + ' - ' + emissor.razaoSocial;
    }

    public renderModoEntrada = ( data ) => {
        const retorno = [
            'Não Identificado',
            'CT-e',
            'Coleta',
            'DF-e',
            'Cotação de Frete',
            'Módulo de Integração de Notas Fiscais',
            'Manifesto',
            'Inclusão manual de NF-e',
        ];
        return retorno[data];
    }

    public renderSituacao = ( data ) => {
        return (data === 'REALIZADA' ? 'fa fa-check' : 'fa fa-times') + ' text-center';
    }

    public renderSituacaoTitle = ( data ) => {
        return data === 'REALIZADA' ? 'Realizada' : 'Não Realizada';
    }
}
