export class TipoColeta {

    public static NO_CLIENTE = new TipoColeta('COLETA_MANUAL', 0, 'No Cliente');
    public static NO_DEPOSITO = new TipoColeta('COLETA_AGENDA', 1, 'No Depósito');


    private constructor(
        private _key: string,
        private _value: number,
        private _description: string,
    ) {}

    public toString() {
        return this._key;
    }

    get value(): number {
        return this._value;
    }

    get description(): string {
        return this._description;
    }
}

export class TipoColetaLookup {

    public static lookup = [
        TipoColeta.NO_CLIENTE,
        TipoColeta.NO_DEPOSITO,
    ].sort((a, b) => a.value - b.value); // mantém o array ordenado pelo valor

    public static default = TipoColeta.NO_CLIENTE;

    private constructor() {}
}
