












import {namespace} from 'vuex-class';
import {Component, Vue} from 'vue-property-decorator';
import {StatusColeta, StatusColetaLookup} from '@/enums/StatusColeta';

const AuthStore = namespace('AuthStore');

@Component({
    name: 'SelectStatusColeta',
})

export default class SelectStatusColeta extends Vue {

    public value: string = '';

    public get options(): StatusColeta[] {
        return StatusColetaLookup.lookup;
    }
}
