































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {Company} from '@/models/Company';
import {StatusColeta, StatusColetaLookup} from '@/enums/StatusColeta';
import Documentos from '@/components/collection/mostrador/Documentos.vue';
import Destinatarios from '@/components/collection/mostrador/Destinatarios.vue';
import Ocorrencias from '@/components/collection/mostrador/Ocorrencias.vue';
import DestinoSimples from '@/components/collection/mostrador/DestinoSimples.vue';
import DadosGerais from '@/components/collection/mostrador/DadosGerais.vue';
import {FormaColeta, FormaColetaLookup} from '@/enums/FormaColeta';

const AuthStore = namespace('AuthStore');

@Component({
    name: 'CollectionActions',
    components: {Ocorrencias, Destinatarios, Documentos, DestinoSimples, DadosGerais},
})
export default class CollectionActions extends Vue {

    @AuthStore.State
    public currentCompany!: Company;

    @Prop()
    public rowData: any;

    public mostraData: any = null;

    public dialogVisible: boolean = false;

    public openModal(coletaId: number): void {
        this.mostraData = null;
        this.$axios
            .get(
                `/collection/mostrador/${this.currentCompany.id}`,
                {params: {coletaId}},
            ).then((response) => {
                this.mostraData = response.data;
                this.dialogVisible = true;
            }).catch((error) => {
                console.log(error);
                Vue.$toast.info('Não foi possível carregar os dados no momento, tente novamente mais tarde!');
            });
    }

    public getStatus(status: number): StatusColetaLookup {
        return StatusColetaLookup?.lookup?.find((s) => s.value === status) || StatusColeta.NOVO;
    }

    public renderFormaColeta(status: number, full: any): StatusColetaLookup {
        const retVal = {
            forma: FormaColetaLookup?.lookup?.find((s) => s.value === status) || FormaColeta.COLETA_MANUAL,
            desc: '',
        };
        switch (retVal.forma.value) {
            case 1:
                retVal.desc = 'Coleta gerada manualmente';
                break;
            case 2:
                retVal.desc = full.coletaAgendaId ?
                    'Coleta gerada automaticamente pela agenda de código: ' + full.coletaAgendaId :
                    'Coleta gerada automaticamente';
                break;
            case 3:
                retVal.desc = 'Coleta gerada a partir do portal de relacionamento por ' + full.nomeUsuarioPortal
                    + ' - ' + full.loginUsuarioPortal;
                break;
        }
        return retVal;
    }

    public coletaEmailStatus(status: number) {
        const ces = [
            {
                cod: 0,
                nome: 'Fila/Enviar',
                type: 'info',
                desc: 'Email na fila de envio',
            },
            {
                cod: 1,
                nome: 'Enviado',
                type: 'success',
                desc: 'Email enviado com sucesso',
            },
            {
                cod: 2,
                nome: 'Erro',
                type: 'danger',
                desc: 'Falha ao enviar e-mail',
            },
            {
                cod: 3,
                nome: 'Email sem destinatário',
                type: 'warning',
                desc: 'Nenhum endereço de email foi localizado para envio',
            },
            {
                cod: 4,
                nome: 'Não marcado para envio',
                type: 'default',
                desc: 'Email não marcado para envio',
            },
            {
                cod: 5,
                nome: 'Status não permite envio',
                type: 'Warning',
                desc: 'Status da coleta não permite envio por e-mail',
            },
        ];
        return ces[status];
    }
}
