















































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {Company} from '@/models/Company';
import {Utils} from '@/utils/Utils';
import {UfLookup} from '@/enums/Uf';

const AuthStore = namespace('AuthStore');

@Component({
    name: 'Destinatarios',
})
export default class Destinatarios extends Vue {

    @AuthStore.State
    public currentCompany!: Company;

    @Prop()
    public destinatariosList: any;

    public renderCliente(cliente): string {
        if (!cliente || !cliente.id) {
            return 'Não Informado';
        }
        return Utils.formatCnpjCpf(cliente.cnpjCpf) + ' - ' + cliente.razaoSocial;
    }

    public renderCidade(cidade): string {
        if (!cidade || !cidade.id) {
            return 'Não Informada';
        }
        return cidade.nome + ' - ' + UfLookup.getUfByCod(cidade.codUf).toString();
    }

    public renderDec2(val) {
        if (!val) {
            return '0,00';
        }
        return Number(val).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    }

    public renderNatureza(full): string {
        return full.naturezaDescricao ? full.naturezaDescricao : '-';
    }

    public renderTipoFrete = ( data ) => {
        switch (data) {
            case '0':
                return 'Pago';
            case '1':
                return 'A Pagar';
            case '2':
                return 'Cortesia';
            default:
                return 'Não Definido';
        }
    }

    public renderFormaPagamento = ( data ) => {
        switch (data) {
            case '0':
                return 'À Vista';
            case '1':
                return 'A Pagar';
            default:
                return 'Não Definido';
        }
    }
}
