











import {Component, Vue} from 'vue-property-decorator';
import {Uf, UfLookup} from '@/enums/Uf';

@Component({
    name: 'SelectUfSigla',
})
export default class SelectUfSigla extends Vue {

    public value: string = '';

    public get options(): Uf[] {
        return UfLookup.lookup;
    }
}
