




import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'FilterByItem',
})
export default class FilterByItem extends Vue {

  @Prop()
  public readonly label!: string;

  @Prop()
  public readonly value!: any;

  public get show() {
    return !!this.value;
  }
}
