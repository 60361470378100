





































import {Component, Prop, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {Company} from '@/models/Company';

const AuthStore = namespace('AuthStore');

@Component({
    name: 'Ocorrencias',
})
export default class Ocorrencias extends Vue {

    @AuthStore.State
    public currentCompany!: Company;

    @Prop()
    public ocorrenciasList: any;

    public filterBrDateTime = Vue.filter('brDateTime');

    public renderData(param) {
        if (!param.value) {
            return '-';
        }
        return this.filterBrDateTime(new Date(param.value));
    }
}
