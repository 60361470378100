export class FileTransferType {

  public static XML = new FileTransferType('XML', 'xml', 'text/xml', 'text');
  public static PDF = new FileTransferType('PDF', 'pdf', 'application/pdf', 'arraybuffer');
  public static TXT = new FileTransferType('TXT', 'txt', 'application/pdf', 'arraybuffer');
  public static ZIP = new FileTransferType('ZIP', 'zip', 'application/json', 'arraybuffer');
  public static JPG = new FileTransferType('JPG', 'jpg', 'image/jpeg', 'arraybuffer');
  public static EXCEL = new FileTransferType('EXCEL', 'xlsx',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'arraybuffer');

  private constructor(
      private _key: string,
      private _extention: string,
      private _accceptHeader: string,
      private _responseType: any,
  ) {}

  public toString() {
    return this._key;
  }

  get extention(): string {
    return this._extention;
  }

  get accceptHeader(): string {
    return this._accceptHeader;
  }

  get responseType(): any {
    return this._responseType;
  }
}
