











import {Component, Prop, Emit, Vue} from 'vue-property-decorator';

@Component({
    name: 'FilterBy',
})

export default class FilterBy extends Vue {

    @Prop()
    public readonly filters!: any;

    @Emit('cleanFilter')
    public cleanFilter(): void {}

    public get show() {
        // se nao tiver valor nos filtros trata para nao mostrar tambem
        let isShowFlag = false;

        Object.values(this.filters).forEach( (value, key) => {
            // ignora pos 0 pq é a chave 'show' que vem das classes ...Filters() ex: FreightFormFilters
            if (key !== 0) {
                if (value !== '' && value !== null) {
                    isShowFlag = true;
                }
            }
        });

        if (!isShowFlag) {
            this.filters.show = false;
        }

        return this.filters && Object.values(this.filters)[0] === true;
    }
}
