export class FormaColeta {

    public static COLETA_MANUAL = new FormaColeta('COLETA_MANUAL', 1, 'Coleta Manual', 'info', 'user');
    public static COLETA_AGENDA = new FormaColeta('COLETA_AGENDA', 2, 'Coleta Agenda', 'success', 'book');
    public static PORTAL = new FormaColeta('PORTAL', 3, 'Portal', 'primary', 'computer');

    private constructor(
        private _key: string,
        private _value: number,
        private _description: string,
        private _type: string,
        private _icon: string,
    ) {}

    public toString() {
        return this._key;
    }

    get value(): number {
        return this._value;
    }

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = value;
    }

    get type(): string {
        return this._type;
    }

    get icon(): string {
        return this._icon;
    }
}

export class FormaColetaLookup {

    public static lookup = [
        FormaColeta.COLETA_MANUAL,
        FormaColeta.COLETA_AGENDA,
        FormaColeta.PORTAL,
    ].sort((a, b) => a.value - b.value); // mantém o array ordenado pelo valor

    public static default = FormaColeta.PORTAL;

    private constructor() {}
}
