



















































































































































































































































































import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {Form} from '@/types';
import {Company} from '@/models/Company';
import {FileTransferType} from '@/enums/FileTransferType';
import FilterBy from '@/components/commons/FilterBy.vue';
import FilterByItem from '@/components/commons/FilterByItem.vue';
import Filters from '@/utils/Filters';
import {DateUtils} from '@/utils/DateUtils';
import {Utils} from '@/utils/Utils';
import {Range} from '@/models/Range';
import ErrorMessage from '@/components/commons/ErrorMessage.vue';
import {Authority} from '@/models/Authority';
import {CollectionRequestListInfo} from '@/models/CollectionRequestListInfo';
import {UfLookup} from '@/enums/Uf';
import SelectStatusColeta from '@/components/collection/SelectStatusColeta.vue';
import SelectFormaColeta from '@/components/collection/SelectFormaColeta.vue';
import SelectTipoColeta from '@/components/collection/SelectTipoColeta.vue';
import CollectionActions from '@/components/collection/CollectionActions.vue';
import SelectUfSigla from '@/components/uf/SelectUfSigla.vue';

Vue.use(Filters);

const AuthStore = namespace('AuthStore');

export class CollectionFormFilters {
    public show: boolean = false;
    public id: string = '';

    public dataColeta: string = '';
    public dataSolicitacao: string = '';
    public dataRealizacao: string = '';

    public telefone: string = '';

    public cep: string = '';

    public cidade: any = {
        nome: null,
        codUf: null,
    };

    public cliente: any = {
        cnpj: null,
    };

    public empresaResp: any = {
        cnpj: null,
    };

    public status: string = '';
    public formaColeta: string = '';
    public tipoColeta: string = '';

    public codPedido: string = '';

    public hoje: string = '';
    public atraso: string = '';
    public urgente: string = '';
    public pesqSomenteComNfe: string = '';
}

@Component({
    name: 'CollectionRequest',
    components: {
        SelectStatusColeta,
        SelectFormaColeta,
        SelectTipoColeta,
        SelectUfSigla,
        FilterBy,
        FilterByItem,
        ErrorMessage,
        CollectionActions,
    },
})

export default class CollectionRequest extends Vue {

    public $refs!: {
        table: any,
        drawer: any,
        formaPagamento: any,
        form: Form,
    };

    @AuthStore.State
    public errorMessage!: string;

    @AuthStore.State
    public currentCompany!: Company;

    @AuthStore.State
    public authorityList!: Authority[];

    @AuthStore.Getter
    public isAuthenticated!: boolean;

    @AuthStore.Action
    public updateAuthorities!: () => Promise<void>;

    @AuthStore.Mutation
    public setErrorMessage!: (newErrorMessage: string) => Promise<void>;

    // drawer
    public dialog: boolean = false;

    // filters
    public filterBrDate = Vue.filter('brDate');
    public filterBrDateTime = Vue.filter('brDateTime');

    public filterCurrencyStringToNumber = Vue.filter('currencyStringToNumber');

    /*
    * propriedade pra filtro de busca
    */
    public formFilters: CollectionFormFilters = new CollectionFormFilters();

    public formLabelWidth: any = '120px';

    public isLoading: boolean = false;

    public totalRecords: number = 0;

    /*
    * Altura default da tabela da listagem
    */
    public tableHeight: string = '300px';

    public zip: FileTransferType = FileTransferType.ZIP;

    public serverParams: any = {
        columnFilters: {},
        sort: {
            field: 'id',
            type: 'asc',
        },
        page: 1,
        perPage: 50,
    };

    /**
     * Regras de validacao
     */
    public rules: any = {
    };

    public columns = [
        {
            label: 'Código',
            sortable: true,
            width: '70px',
            field: 'id',
            tdClass: 'text-center',
            thClass: 'text-center',
        },
        {
            label: 'Emp. Resp.',
            sortable: true,
            width: '70px',
            formatFn: this.formatEmpFn,
            field: 'empresaResp.id',
            tdClass: 'text-center',
            thClass: 'text-center',
            type: 'string',
        },
        {
            label: 'Agendamento',
            sortable: true,
            width: '90px',
            field: 'dataColeta',
            type: 'date',
            formatFn: this.formatDateFn,
            tdClass: 'text-center',
            thClass: 'text-center',
        },
        {
            label: 'Solicitação',
            sortable: true,
            width: '100px',
            field: 'dataSolicitacao',
            type: 'date',
            formatFn: this.formatDateTimeFn,
            tdClass: 'text-center',
            thClass: 'text-center',
        },
        {
            label: 'Dias em Atraso',
            sortable: true,
            width: '100px',
            field: 'atraso',
            formatFn: this.formatAtraso,
            tdClass: 'text-center',
            thClass: 'text-center',
            type: 'string',
        },
        {
            label: 'Realização',
            sortable: true,
            width: '90px',
            field: 'dataRealizacao',
            type: 'date',
            formatFn: this.formatDateFn,
            tdClass: 'text-center',
            thClass: 'text-center',
        },
        {
            label: 'Criado por',
            sortable: true,
            width: '150px',
            field: 'usuarioInclusao.name',
            tdClass: 'text-left',
            thClass: 'text-left',
            type: 'string',
        },
        {
            label: 'Hora',
            sortable: true,
            width: '100px',
            field: 'horaFixa',
            formatFn: this.renderHora,
            tdClass: 'text-center',
            thClass: 'text-center',
            type: 'string',
        },
        {
            label: 'Cliente',
            sortable: true,
            width: '180px',
            field: 'cliente.razaoSocial',
            tdClass: 'text-left',
            thClass: 'text-left',
            type: 'string',
        },
        {
            label: 'Local',
            sortable: true,
            width: '200px',
            field: 'cidade.codUf',
            formatFn: this.formatLocal,
            tdClass: 'text-left',
            thClass: 'text-left',
            type: 'string',
        },
        {
            label: 'Detalhes',
            sortable: true,
            width: '250px',
            field: 'status',
            tdClass: 'text-left',
            thClass: 'text-left',
            type: 'string',
        },
    ];

    /*
    * linhas da tebela
    */
    public rows: CollectionRequestListInfo[] = [] as CollectionRequestListInfo[];

    public formatDateFn(param, full) {
        if (!param.value) {
            return '-';
        }
        const date: Date = new Date(param.value);
        date.setTime(date.getTime() + (3 * 60 * 60 * 1000));
        return this.filterBrDate(date);
    }

    public formatDateTimeFn(param) {
        if (!param.value) {
            return '-';
        }
        const date: Date = new Date(param.value);
        const time = this.filterBrDateTime(date).split(':');
        return time[0] + ':' + time[1];
    }

    public formatAtraso(data, full) {

        const dataRealizacao = full.dataRealizacao.value;

        const date: Date = new Date(full.dataColeta.value);
        date.setTime(date.getTime() + (3 * 60 * 60 * 1000));

        const diff = DateUtils.diferencaDias(date, new Date()) - 1;

        return dataRealizacao || diff <= 0 ? '-' : diff;
    }

    public formatEmpFn(param, full) {
        return param ? param + ' - ' + full.empresaResp.sigla : '-';
    }

    public renderHora(param, full ) {
        if (full.horaFixa) {
            return 'às ' + DateUtils.removeSegundos(full.horaFixa);
        } else if (full.horaInicio && full.horaFinal) {
            return 'de ' + DateUtils.removeSegundos(full.horaInicio) +
                ' até ' + DateUtils.removeSegundos(full.horaFinal);
        } else if (full.horaInicio && !full.horaFinal) {
            return 'a partir das ' + DateUtils.removeSegundos(full.horaInicio);
        } else if (!full.horaInicio && full.horaFinal) {
            return 'até ' + DateUtils.removeSegundos(full.horaFinal);
        }
        return 'Não Informado';
    }

    public formatLocal(param, full) {
        let retval = full.logradouro + ', ' + full.numero;
        if (full.complemento) {
            retval += ', ' + full.complemento;
        }
        if (full.cidade.nome && full.cidade.codUf) {
            retval = this.buscaUf(full.cidade.codUf) + ' - ' + full.cidade.nome + ' - ' + retval;
        }
        return retval;
    }

    public buscaUf(ufCod) {
        return UfLookup.getUfByCod(ufCod).toString();
    }

    /*
    * dispara a busca e fecha formulário
    */
    public search() {
        this.$refs.form.validate((valid) => {
            if (valid) {
                this.formFilters.show = true;
                this.$refs.drawer.closeDrawer();
                this.onPageChange('1');
            } else {
                return false;
            }
        });
    }

    public formatString(str, len = 50, reduce = 47): string {
        return Utils.formatSizeStringRow(str, len, reduce);
    }

    public updateParams(newProps): void {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
    }

    public onPageChange(params): void {
        this.updateParams({page: params.currentPage});
        this.loadItems();
    }

    public onPerPageChange(params): void {
        this.updateParams({perPage: params.currentPerPage});
        this.loadItems();
    }

    public onSortChange(params): void {
        if (params.length) {
            const fieldParams = params[0].field;
            this.updateParams({
                sort: {
                    field: fieldParams,
                    type: params[0].type,
                },
            });
        }
        this.loadItems();
    }

    public loadItems(): void {
        if (!this.currentCompany.id) {
            return;
        }
        this.applyFilters();
        this.$axios
            .post(
                `/collection/list/${this.currentCompany.id}`,
                this.serverParams.columnFilters,
                {
                    params: Object.assign({}, this.serverParams),
                },
            )
            .then((response) => {
                const data = response.data || {};
                this.totalRecords = data.recordsFiltered;
                this.rows = data.resources || [];
            }).catch((error) => {
            console.log(error);
            Vue.$toast.info('Não foi possível carregar os dados no momento, tente novamente mais tarde!');
        }).finally(() => this.isLoading = false);
    }

    public getFiltersToExport(): any {
        return this.serverParams;
    }

    public calcPositionTable(): void {
        if (this.$refs.table != null) {
            const tableHeader = document.getElementsByClassName('vgt-fixed-header');
            const tableFooter = document.getElementsByClassName('vgt-wrap__footer');
            const headerPos = tableHeader[0].getBoundingClientRect();
            const footerPos = tableFooter[0].getBoundingClientRect();
            const calc = footerPos.top - (headerPos.bottom - headerPos.height);
            this.tableHeight = calc.toFixed(0);
        }
    }

    public getFixedHeigthTable(): string {
        return `${this.tableHeight}px`;
    }

    public cancelForm(): void {
        this.dialog = false;
    }

    /**
     * abre formulario de pesquisa
     */
    public openFormPesq(): void {
        this.setErrorMessage('');
        this.applyMask();
    }

    /**
     * Mascara de formatacao
     */
    public applyMask() {
        const im = new Inputmask('99/99/9999');
    }

    /**
     * Reset filtros de pesquisa
     */
    public cleanAll(): void {
        this.formFilters.show = false;
        this.onRefreshClick();
    }

    public hasAuthOnCurrentCompany(auth: string, currentCompany: Company): boolean {
        return this.authorityList.some(
            (authority) => authority.empresaLoginId === currentCompany.id && authority.auth === auth,
        );
    }

    /*
    * aplica os filtros de busca por item da página
    */
    private applyFilters() {
        this.serverParams.columnFilters = Object.assign(
            {},
            {
                id: this.formFilters.id || null,
                dataColeta: this.formFilters.dataColeta ?
                    new Range(
                        this.formFilters.dataColeta[0] + DateUtils.getMinAndMaxTime().min,
                        this.formFilters.dataColeta[1] + DateUtils.getMinAndMaxTime().max) : null,
                dataSolicitacao: this.formFilters.dataSolicitacao ?
                    new Range(
                        this.formFilters.dataSolicitacao[0] + DateUtils.getMinAndMaxTime().min,
                        this.formFilters.dataSolicitacao[1] + DateUtils.getMinAndMaxTime().max) : null,
                dataRealizacao: this.formFilters.dataRealizacao ?
                    new Range(
                        this.formFilters.dataRealizacao[0],
                        this.formFilters.dataRealizacao[1]) : null,
                cep: this.formFilters.cep || null,
                cidade: this.formFilters.cidade || null,
                cliente: this.formFilters.cliente || null,
                empresaResp: this.formFilters.empresaResp || null,
                codPedido: this.formFilters.codPedido || null,
                status: this.formFilters.status || null,
                formaColeta: this.formFilters.formaColeta || null,
                tipoColeta: this.formFilters.tipoColeta || null,
            },
        );
    }

    private onRefreshClick(): void {
        this.formFilters = new CollectionFormFilters();
        this.serverParams = {
            columnFilters: {},
            page: 1,
            perPage: 50,
        };
        this.$refs.table.$refs.paginationBottom.currentPage = 1;
        this.$refs.table.reset();
    }

    private hasPermission() {
        return this.hasAuthOnCurrentCompany(this.$route.meta.permission, this.currentCompany);
    }

    private beforeMount(): void {
        if (this.isAuthenticated) {
            this.updateAuthorities();
        }
        if (!this.hasPermission()) {
            this.$router.push('dashboard');
        }
    }

    private mounted() {
        this.$nextTick(() => {
            this.calcPositionTable();
        });
        window.addEventListener('resize', this.calcPositionTable);
    }

    private unmounted() {
        window.removeEventListener('resize', this.calcPositionTable);
    }
}
