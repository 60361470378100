import { render, staticRenderFns } from "./DestinoSimples.vue?vue&type=template&id=75162f9a&scoped=true&"
import script from "./DestinoSimples.vue?vue&type=script&lang=ts&"
export * from "./DestinoSimples.vue?vue&type=script&lang=ts&"
import style0 from "./DestinoSimples.vue?vue&type=style&index=0&id=75162f9a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75162f9a",
  null
  
)

export default component.exports