
import moment from 'moment';

export class DateUtils {

    public static getMaxTime(): string {
        return moment().endOf('day').format('THH:mm:ss');
    }

    public static getMinTime(): string {
        return moment().startOf('day').format('THH:mm:ss');
    }

    public static getMinAndMaxTime(): any {
        return {
            max: this.getMaxTime(),
            min: this.getMinTime(),
        };
    }

    /**
     * Realiza a comparação de duas datas retornando a diferença delas em dias
     * @param data1 objeto Date()
     * @param data2 objeto Date()
     * @return {number} diferença em dias
     */
    public static diferencaDias( data1, data2 ): number {
        const diff = data2.getTime() - data1.getTime();
        return Math.ceil(diff / (1000 * 3600 * 24));
    }

    public static removeSegundos(hora): string {
        if (!hora) {
            return '';
        }
        const horaArray = hora.split(':');
        return horaArray[0] + ':' + horaArray[1];
    }
}
