












import {namespace} from 'vuex-class';
import {Component, Vue} from 'vue-property-decorator';
import {FormaColetaLookup, FormaColeta} from '@/enums/FormaColeta';

const AuthStore = namespace('AuthStore');

@Component({
    name: 'SelectFormaColeta',
})

export default class SelectFormaColeta extends Vue {

    public value: string = '';

    public get options(): FormaColeta[] {
        return FormaColetaLookup.lookup;
    }
}
